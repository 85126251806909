import { Injectable } from '@angular/core';

import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Injectable()
export class AuthService {

  constructor(private adalService: MsAdalAngular6Service) {}

  public init() {}

  public logOut(): void {
    this.adalService.logout();
  }

  public logIn(): void {
    this.adalService.login();
  }

  public get isAuthenticated(): boolean {
    return !!this.adalService.isAuthenticated;
  }

  public handleWindowCallback(): void {
    this.adalService.handleCallback();
  }

  public get userName(): string {
    return this.adalService.userInfo.userName;
  }
}
