import { Injectable } from '@angular/core';

import { ajaxGet } from 'rxjs/internal-compatibility';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { EnvironmentSettings } from '../models/environment-settings.model';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentSettingsService {
  constructor() { }
  private _config?: EnvironmentSettings;
  public get config(): EnvironmentSettings {
    if (!this._config) {
      throw Error('config must be set before it is accessed');
    }

    return this._config;
  }

  adalConfig: any = null;

  init(): Promise<EnvironmentSettings> {
    const url = environment.production ? '../../../assets/settings/environment-settings.prod.json' :
      '../../../assets/settings/environment-settings.json';

    return ajaxGet(url)
      .pipe(
        map((result: { response: EnvironmentSettings }) => ({
          ...result.response
        })),
        tap(config => {
          this._config = config as EnvironmentSettings;

          this.adalConfig = {
            tenant: config.auth.tenant,
            clientId: config.auth.clientId,
            redirectUri: `${config.clientUrl}`,
            endpoints: config.apiUrl,
            navigateToLoginRequestUrl: false,
            cacheLocation: 'localStorage',
          };

        }),
        catchError(err => {
          return of(err);
        }),
      )
      .toPromise() as Promise<EnvironmentSettings>;
  }
}

export function initConfig(environmentSettingsService: EnvironmentSettingsService) {
  return () => environmentSettingsService.init();
}

export function getAdalConfig(environmentSettingsService: EnvironmentSettingsService) {
  return environmentSettingsService.adalConfig;
}