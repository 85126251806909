<mat-toolbar color="primary">
  <h1 style="font-size: 30px;">Quest Agent Monitor</h1>
  <span class="spacer"></span>
  <ul>
    <li *ngIf="!isLoggedIn"><a (click)="login()">Login</a></li>
    <li *ngIf="isLoggedIn">{{ authService.userName }}</li>
    <li *ngIf="isLoggedIn"><a (click)="logout()">Logout</a></li>
  </ul>
</mat-toolbar>
<mat-toolbar class="sub-toolbar">
  <ul>
    <li><a [routerLink]="['/dashboard']" routerLinkActive="active">Dashboard</a></li>
    <li *ngIf="isLoggedIn"><a [routerLink]="['/agent-pools']" routerLinkActive="active">Agent Pools</a></li>
    <li *ngIf="isLoggedIn"><a [routerLink]="['/rules-config']" routerLinkActive="active">Rules Config</a></li>
  </ul>
</mat-toolbar>

<router-outlet class="content"></router-outlet>
