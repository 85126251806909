import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './core/layout/components/layout.component';
import { IsLoggedInGuard } from './shared/authentication/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/features/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'agent-pools',
        canActivateChild: [IsLoggedInGuard],
        loadChildren: () => import('src/app/features/agent-pools/agent-pools.module').then(m => m.AgentPoolsModule)
      },
      {
        path: 'rules-config',
        canActivateChild: [IsLoggedInGuard],
        loadChildren: () => import('src/app/features/rules-config/rules-config.module').then(m => m.RulesConfigModule)
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
