import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AuthenticationGuard, MsAdalAngular6Module, MsAdalAngular6Service } from 'microsoft-adal-angular6';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './shared/material.module';
import { LayoutComponent } from './core/layout/components/layout.component';
import { AuthService } from './shared/authentication/auth.service';
import { IsLoggedInGuard } from './shared/authentication/auth.guard';
import { EnvironmentSettingsService, getAdalConfig, initConfig } from './shared/services/environment-settings.service';
import { InsertAuthTokenInterceptor } from './authentication/InsertAuthTokenInterceptor';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    MsAdalAngular6Module,
  ],
  providers: [
    AuthService,
    IsLoggedInGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [EnvironmentSettingsService],
      multi: true,
    },
    MsAdalAngular6Service,
    {
      provide: 'adalConfig',
      useFactory: getAdalConfig,
      deps: [EnvironmentSettingsService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InsertAuthTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
