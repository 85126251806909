import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/shared/authentication/auth.service';

@Component({
  selector: 'qam-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss']
})

export class LayoutComponent implements OnInit {
  constructor(public authService: AuthService) { }

  ngOnInit() { }

  get isLoggedIn(): boolean {
    return this.authService.isAuthenticated;
  }

  login() {
    this.authService.logIn();
  }

  logout() {
    this.authService.logOut();
  }

}
