import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class IsLoggedInGuard implements CanActivateChild {
  constructor(private authService: AuthService) { }

  canActivateChild() {
    return this.authService.isAuthenticated;
  }
}
